import React, { useCallback, useMemo } from "react";
import { get } from "lodash";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { BaseRow, CellProps, RowProps, RowType } from "@shared/components/DataGrid/models";
import { Cell } from "@shared/components/DataGrid/components";

const fontWeightMap = {
  [RowType.Regular]: "fontWeightMedium",
  [RowType.SubHeader]: "fontWeightBold",
  [RowType.BoldHeader]: "fontWeightBold",
  [RowType.Highlighted]: "fontWeightMedium",
  [RowType.Divider]: "fontWeightRegular",
  [RowType.Video]: "fontWeightMedium",
};
const bgColorsMap = {
  [RowType.Regular]: "grey.100",
  [RowType.SubHeader]: "grey.300",
  [RowType.BoldHeader]: "grey.300",
  [RowType.Highlighted]: "accent.light",
  [RowType.Divider]: "common.white",
  [RowType.Video]: "grey.100",
};
const defaultMeta = {
  type: RowType.Regular,
};

const useStyles = makeStyles<Theme, { type: RowType; alternateRowColor: boolean | undefined }>((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
    height: ({ alternateRowColor }) => (alternateRowColor ? 58 : 50),
    width: "100%",
    margin: ({ alternateRowColor }) => (alternateRowColor ? theme.spacing(0, 0) : theme.spacing(0, 2)), //theme.spacing(0, 0),
    padding: ({ type }) => (type === RowType.Divider ? theme.spacing(0, 2.5) : "initial"),
    fontStyle: ({ type }) => (type === RowType.Divider ? "italic" : "inherit"),
    fontSize: ({ type }) => (type === RowType.SubHeader || type === RowType.BoldHeader ? 15 : 14),
    fontWeight: ({ type }) => get(theme.typography, fontWeightMap[type]),
    background: ({ alternateRowColor, type }) => (alternateRowColor ? "" : get(theme.palette, bgColorsMap[type])), //get(theme.palette, bgColorsMap[type]),

    "&:hover": {
      background: ({ type }) =>
        type !== RowType.SubHeader && type !== RowType.Divider && type === RowType.Highlighted
          ? theme.palette.accent.main
          : theme.palette.grey["300"],
    },
  },
  evenRow: { backgroundColor: "#EBEBEB" },
  oddRow: { backgroundColor: theme.palette.grey[50] }, //{ backgroundColor: theme.palette.common.white },
  rowDivider: { borderTop: "1px solid rgba(9, 53, 73, 0.6)", backgroundColor: theme.palette.common.white },
  boldHeader: { borderTop: "3px solid rgba(9, 53, 73, 0.7)", backgroundColor: theme.palette.common.white },
  regulorRow: { backgroundColor: "#EBEBEB" },
}));

const Row = <D extends BaseRow>(props: RowProps<D>) => {
  const meta = useMemo(() => ({ ...defaultMeta, ...(props.original.rowMeta || {}) }), [props.original.rowMeta]);
  const classes = useStyles({ type: meta.type, alternateRowColor: props.alternateRowColor });

  const renderCell = useCallback((c: CellProps<D>) => {
    const { key } = c.getCellProps();

    return <Cell {...c} key={key} />;
  }, []);

  const getClassRow = () => {
    let rowClass = "";

    if (props.alternateRowColor) {
      if (props.rowColor === "light") {
        rowClass = `${classes.oddRow}`;
      } else if (props.rowColor === "grey") {
        rowClass = `${classes.evenRow}`;
      } else if (props.rowColor === "bold") {
        rowClass = `${classes.boldHeader}`;
      } else if (props.rowColor === "divider") {
        rowClass = `${classes.rowDivider}`;
      }
    }

    return rowClass;
  };

  const alterClass = getClassRow();

  return (
    <div {...props.getRowProps({ style: props.style })}>
      <div data-testid="datagrid-row" className={`${classes.row} ${alterClass} `}>
        {meta.type === RowType.Divider ? props.cells[0].value : props.cells.map(renderCell)}
      </div>
    </div>
  );
};

export default Row;
