export enum Param {
  Currency = "Destination Currency:ISOCode",
  Date = "Date:CalendarQuarterEndfDate",
  Investor = "UPC:InvestorName",
  Fund = "UPC:FundName",
  MandateFund = "UPC:MandateFund",
  Portfolio = "UPC:Portfolio",
  GeneralPartner = "UPC:General Partner",
  WorldRegion = "UPC:WorldRegion",
  SecondaryDeal = "UPC:SecondaryDealProjectName",
  Country = "UPC:Country",
  Sector = "UPC:Sector",
  IndustryGroup = "UPC:IndustryGroup",
  VintageYear = "UPC:VintageYear",
  CompanyName = "UPC:CompanyName",
}
