import { DiversificationAnalysisState } from "@diversificationAnalysis/models";
import { RootState } from "@store/index";

export const diversificationAnalysisEmbedUrlSelector = (state: RootState) =>
  state.diversificationAnalysis.embedUrlConfig;
export const diversificationAnalysisExportSelector = (state: RootState) => state.diversificationAnalysis.export;

export const diversificationAnalysisReportExportLoadingSelector = (
  state: RootState
): DiversificationAnalysisState["isReportDownloading"] => state.diversificationAnalysis.isReportDownloading;
