import React, { FunctionComponent, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import { format } from "date-fns";
import theme from "@app/constants/theme";
import { AppRoute } from "@app/models/AppRoute";
import { Button } from "@shared/components";
import { getEST, getUTC } from "@insightsHub/util/DateFormat";
import { ContentButtonSize, InsightsMainContentProps } from "./models";
import ContentTypeChip from "./ContentTypeChip";

const useStyles = makeStyles(() => ({
  title: {
    letterSpacing: 0,

    [theme.breakpoints.up("xl")]: {
      fontSize: 60,
    },
    [theme.breakpoints.between("lg", "xl")]: {
      fontSize: 35,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 28,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 24,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 43,
      letterSpacing: 0.63,
    },
  },
  carouselTextContainer: {
    background: theme.palette.common.white,
    flexWrap: "nowrap",
    [theme.breakpoints.up("xl")]: {
      padding: theme.spacing(16, 13),
      height: 542,
    },
    [theme.breakpoints.between("lg", "xl")]: {
      height: 390,
      padding: theme.spacing(13, 11),
    },
    [theme.breakpoints.between("md", "lg")]: {
      height: 330,
      maxWidth: 460,
      padding: theme.spacing(0, 10),
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "auto",
      height: 270,
      padding: theme.spacing(0, 9),
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: 400,
      padding: theme.spacing(4, 8),
    },
    justifyContent: "center",
    textAlign: "left",
  },
  slideTitle: {
    width: "100%",
    textAlign: "left",
    webkitLineClamp: 2,
    lineClamp: 2,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    boxOrient: "vertical",
    paddingTop: theme.spacing(6),
    [theme.breakpoints.up("xl")]: {
      fontSize: 28,
      letterSpacing: 1,
      lineHeight: 1,
    },
    [theme.breakpoints.between("lg", "xl")]: {
      fontSize: 28,
      letterSpacing: 0.56,
      lineHeight: 1,
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: 28,
      letterSpacing: 0,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 25,
      letterSpacing: 0.56,
    },
  },
  slideAuthor: {
    margin: 0,
    textTransform: "uppercase",
    fontWeight: 500,
    letterSpacing: 1.2,
    textAlign: "left",
    webkitLineClamp: 1,
    lineClamp: 1,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    boxOrient: "vertical",
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      letterSpacing: 1.2,
    },
  },
  authorBy: {
    fontSize: 12,
    display: "-webkit-box",
    width: 50,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      // letterSpacing: 0.56,
    },
  },
  slideDescription: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    boxOrient: "vertical",
    webkitLineClamp: 3,

    lineClamp: 3,
    [theme.breakpoints.up("xl")]: {
      paddingTop: theme.spacing(7),
      fontSize: 20,
      letterSpacing: 0.4,
      width: "340px",
    },
    [theme.breakpoints.between("lg", "xl")]: {
      paddingTop: theme.spacing(6),
      letterSpacing: 0.3,
      lineHeight: 1.3,
      width: "340px",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: 15,
      width: "340px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      paddingTop: theme.spacing(4.5),
      width: "290px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      paddingTop: theme.spacing(3.5),
      webkitLineClamp: 3,
      lineClamp: 3,
      width: "200px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(5),
      fontSize: 20,
      letterSpacing: 0.4,
      webkitLineClamp: 4,
      lineClamp: 4,
      lineHeight: 1.3,
      width: screen.availWidth - 120,
    },
  },
  slideButton: {
    width: "100%",
    [theme.breakpoints.up("xl")]: {
      paddingTop: theme.spacing(9),
      fontSize: 20,
      letterSpacing: 0.4,
    },

    [theme.breakpoints.between("lg", "xl")]: {
      paddingTop: theme.spacing(7),
    },
    [theme.breakpoints.between("md", "lg")]: {
      paddingTop: theme.spacing(5),
    },
    [theme.breakpoints.between("sm", "md")]: {
      paddingTop: theme.spacing(4),
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: 25,
      letterSpacing: 0.5,
      paddingTop: theme.spacing(5),
    },
  },
  mainImageContainer: {
    position: "relative",
    [theme.breakpoints.up("xl")]: {
      position: "relative",
    },
    [theme.breakpoints.down("sm")]: {
      height: 230,
    },
  },
  slideDate: {
    fontSize: 15,
    // paddingTop: theme.spacing(7),
    letterSpacing: 0.4,
    [theme.breakpoints.up("xl")]: {
      paddingTop: theme.spacing(7),
      fontSize: 15,
      letterSpacing: 0.4,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      letterSpacing: 0.4,
    },
  },

  mainImage: {
    [theme.breakpoints.up("xl")]: {
      width: 965,
      height: 542,
    },
    [theme.breakpoints.between("lg", "xl")]: {
      width: 719,
      height: 390,
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: 606,
      height: 330,
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: 491,
      height: 270,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: 230,
    },
  },
  authorContainer: {
    display: "grid",
    gridTemplateColumns: "17px auto",
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "20px auto",
    },
  },
}));
const InsightsHubMainContent: FunctionComponent<InsightsMainContentProps> = ({
  title,
  url,
  description,
  id,
  contentType,
  authorName,
  publishDate,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const isXLarge = useMediaQuery(theme.breakpoints.up("xl"));
  const isLarge = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const handleLearnMoreClick = useCallback((): void => {
    history.push({ pathname: `${AppRoute.InsightsHub}/${id}`, state: { from: "content-page" } });
  }, [history, id]);

  const buttonSize = useMemo(() => {
    if (isXLarge) {
      return ContentButtonSize.Large;
    }
    if (isSmall) {
      return ContentButtonSize.Small;
    }
    if (isLarge) {
      return ContentButtonSize.Medium;
    }

    return ContentButtonSize.Medium;
  }, [isXLarge, isLarge, isSmall]);

  const formatDate = useCallback((date, publishesAt) => {
    const currentDate = getUTC(new Date().toISOString());

    if (getUTC(date) <= currentDate) {
      return format(Date.parse(getEST(date)), "MMM d, yyyy");
    } else {
      return format(publishesAt, "MMM d, yyyy");
    }
  }, []);

  return (
    <Grid
      container
      sx={{
        flexDirection: {
          xs: "column",
          sm: "row",
        },
      }}
      data-testid="feature-insights-section"
      flexWrap="nowrap"
    >
      <Grid item className={classes.mainImageContainer}>
        <img src={url} className={classes.mainImage} />
        {contentType && (
          <div style={{ position: "absolute", top: 0, left: 0, padding: 0, margin: 0, textTransform: "uppercase" }}>
            <ContentTypeChip label={contentType ? contentType : ""} chipType="content" />
          </div>
        )}
      </Grid>
      <Grid item>
        <Grid container direction="column" className={classes.carouselTextContainer} alignItems="center">
          <Grid item width="100%" className={classes.slideDate}>
            {formatDate(publishDate, publishDate)}
          </Grid>
          <Grid item width="100%">
            <Typography title={title} variant="h5" className={classes.slideTitle}>
              {title}
            </Typography>
          </Grid>
          <Grid item width="100%">
            <div className={classes.authorContainer}>
              <div className={classes.authorBy}>BY</div>
              <div className={classes.slideAuthor}> {authorName}</div>
            </div>
          </Grid>
          <Grid item className={classes.slideDescription} title={description ?? undefined}>
            {description}
          </Grid>
          <Grid item className={classes.slideButton}>
            <Button
              shape="rounded"
              size={buttonSize}
              testId={`insight-learn-more-${id}`}
              onClick={handleLearnMoreClick}
            >
              READ MORE
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InsightsHubMainContent;
