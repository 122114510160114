import { createSlice } from "@reduxjs/toolkit";
import ReduxUtils from "@shared/utils/ReduxUtils";
import { DiversificationAnalysisState } from "@diversificationAnalysis/models";
import { fetchDiversificationAnalysisEmbedUrlThunk } from "@diversificationAnalysis/store/thunks/fetchDiversificationAnalysisEmbedUrlThunk";
import { postDiversificationAnalysisExportThunk } from "@diversificationAnalysis/store/thunks/postDiversificationAnalysisExportThunk";
import { fetchDiversificationAnalysisExportStatusThunk } from "@diversificationAnalysis/store/thunks/fetchDiversificationAnalysisExportStatusThunk";
import { downloadDiversificationAnalysisThunk } from "@diversificationAnalysis/store/thunks/downloadDiversificationAnalysisThunk";

const initialState: DiversificationAnalysisState = {
  embedUrlConfig: ReduxUtils.getAsyncSlice(),
  export: ReduxUtils.getAsyncSlice(),
  isReportDownloading: false,
};

const diversificationAnalysisSlice = createSlice({
  name: "diversificationAnalysis",
  initialState,
  reducers: {
    resetState: (state) => {
      state.embedUrlConfig = initialState.embedUrlConfig;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDiversificationAnalysisEmbedUrlThunk.pending, (state) =>
        ReduxUtils.defaultPendingActionHandler(state.embedUrlConfig)
      )
      .addCase(fetchDiversificationAnalysisEmbedUrlThunk.fulfilled, (state, action) =>
        ReduxUtils.defaultFulfilledActionHandler(state.embedUrlConfig, {
          embedUrl: action.payload.embedUrl,
          reportingDate: action.payload.reportingDate,
        })
      )
      .addCase(fetchDiversificationAnalysisEmbedUrlThunk.rejected, (state, action) =>
        ReduxUtils.defaultRejectedActionHandler(state.embedUrlConfig, action.payload as string)
      )
      .addCase(postDiversificationAnalysisExportThunk.pending, (state) => {
        state.isReportDownloading = true;
        ReduxUtils.defaultFulfilledActionHandler(state.export, {
          status: "Initialized",
        });
      })
      .addCase(postDiversificationAnalysisExportThunk.fulfilled, (state, action) => {
        ReduxUtils.defaultFulfilledActionHandler(state.export, {
          exportId: action.payload.exportId,
          status: "Initialized",
          percentComplete: action.payload.percentComplete,
          resourceFileExtension: action.payload.resourceFileExtension,
        });
      })
      .addCase(postDiversificationAnalysisExportThunk.rejected, (state, action) =>
        ReduxUtils.defaultRejectedActionHandler(state.export, action.payload as string)
      )
      .addCase(fetchDiversificationAnalysisExportStatusThunk.pending, (state) => {
        ReduxUtils.defaultPendingActionHandler(state.export);
      })
      .addCase(fetchDiversificationAnalysisExportStatusThunk.fulfilled, (state, action) => {
        if (action.payload.status === "Failed") {
          state.isReportDownloading = false;
        }
        ReduxUtils.defaultFulfilledActionHandler(state.export, {
          exportId: action.payload.exportId,
          status: action.payload.status,
          percentComplete: action.payload.percentComplete,
          resourceFileExtension: action.payload.resourceFileExtension,
        });
      })
      .addCase(fetchDiversificationAnalysisExportStatusThunk.rejected, (state, action) => {
        state.isReportDownloading = false;
        state.export = initialState.export;
        ReduxUtils.defaultRejectedActionHandler(state.export, action.payload as string);
      })
      .addCase(downloadDiversificationAnalysisThunk.pending, (state) => {
        state.isReportDownloading = true;
      })
      .addCase(downloadDiversificationAnalysisThunk.fulfilled, (state) => {
        state.isReportDownloading = false;
        state.export = initialState.export;
      })
      .addCase(downloadDiversificationAnalysisThunk.rejected, (state) => {
        state.isReportDownloading = false;
        state.export = initialState.export;
      });
  },
});

export const { resetState } = diversificationAnalysisSlice.actions;
export default diversificationAnalysisSlice.reducer;
