import { CSSProperties } from "react";
import { Cell, Column, ColumnInstance, IdType, Row, UseRowSelectInstanceProps } from "react-table";
import { Currency } from "@shared/models/currency";
import { Nullable, SortDirection } from "@shared/models/general";
import { NumberFormat } from "@user/models";
import { ListOptionBase } from "@shared/components/List";

export interface DataTableProps<D extends BaseRow> {
  columns: ReadonlyArray<ColumnMeta<D> & Column<D>>;
  data: Nullable<ReadonlyArray<D & BaseRow>>;
  maxHeight?: number;
  headerVisible?: boolean;
  menu?: HeaderMenuOption[];
  infiniteScrollConfig?: InfiniteScrollConfig;
  onSortChange?(columnId: IdType<D>, direction: SortDirection): void;
  alternateRowColor?: boolean;
}

export interface InfiniteScrollConfig {
  total: number;
  loading: boolean;
  loadMore(start: number): void;
}

export enum HeaderMenuOptionId {
  DOWNLOAD_SELECTED = "DOWNLOAD_SELECTED",
  MARK_READ = "MARK_READ",
  TOGGLE_SELECT_ALL = "TOGGLE_SELECT_ALL",
}

export interface HeaderMenuOption extends ListOptionBase<HeaderMenuOptionId> {
  onClick(rowIds: Array<string>): void;
}

export interface ColumnMeta<D extends BaseRow> {
  headerAlign?: "left" | "center" | "right";
  headerContentAlign?: CSSProperties["justifyContent"];
  align?: "left" | "center" | "right";
  type?:
    | "string"
    | "number"
    | "date"
    | "currency"
    | "readIndicator"
    | "checkbox"
    | "actionFlag"
    | "download"
    | "learnMore"
    | "author";
  currencyFractionDigits?: number;
  numberFormat?: NumberFormat;
  flex?: number | string;
  withTooltip?: boolean;
  letterSpacing?: number | string;
  pinned?: boolean;
  sortable?: boolean;
  sortDirection?: SortDirection;
  minWidth?: number;
  textWrap?: string;
  testId?: string;
  onClick?(row: Row<D>): void;
}

export interface RowMeta<D = Record<string, unknown>> {
  type?: RowType;
  currency?: Currency;
  nonClickableRow?: boolean;
  cellRenderers?: Partial<Record<keyof D, <D extends BaseRow>(props: CellProps<D>) => Nullable<JSX.Element>>>;
}

export interface BaseRow {
  rowMeta?: RowMeta;
  id?: string;
  selected?: boolean;
  download?: boolean;
  isLoading?: boolean;
  learnMore?: boolean;
  alternateRowColor?: boolean;
}

export interface HeaderMenuProps {
  selectedRowIds: string[];
  isAnyRowSelected: boolean;
  toggleAllRowsSelected: UseRowSelectInstanceProps<any>["toggleAllRowsSelected"];
  menu?: HeaderMenuOption[];
}

export type HeaderCellProps<D extends BaseRow> = ColumnInstance<D> &
  ColumnMeta<D> &
  HeaderMenuProps & { onSortChange?: DataTableProps<D>["onSortChange"] };

export type RowProps<D extends BaseRow> = Row<D> & {
  style: CSSProperties;
  selected?: boolean;
  alternateRowColor?: boolean;
  rowColor?: string;
};

export type CellProps<D extends BaseRow> = Cell<D> & {
  column: ColumnInstance<D> & ColumnMeta<D>;
};

export enum RowType {
  Regular = "Regular",
  SubHeader = "SubHeader",
  BoldHeader = "BoldHeader",
  Highlighted = "Highlighted",
  Divider = "Divider",
  Video = "Video",
}
